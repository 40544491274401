export function convertToDate(datetime) {
  return datetime.split('T')[0];
}

export function convertToDateMMddyyyy(datetime, convertFromUTC = true) {
  if (datetime == null) return '-';
  let inputDate = new Date();
  if (convertFromUTC && !datetime.endsWith('Z')) {
    inputDate = new Date(`${datetime}Z`);
  } else {
    inputDate = new Date(datetime);
  }
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'America/New_York',
  })
    .format(inputDate)
    .replaceAll('/', '-');
}

export function convertToDateTime(datetime, convertFromUTC = true) {
  if (datetime == null) return '-';
  let inputDate = new Date();
  if (convertFromUTC && !datetime.endsWith('Z')) {
    inputDate = new Date(`${datetime}Z`);
  } else {
    inputDate = new Date(datetime);
  }
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h12',
    timeZone: 'America/New_York',
  })
    .format(inputDate)
    .replaceAll('/', '-')
    .replaceAll(',', ' ');
}

// eslint-disable-next-line consistent-return
export function extractZipCodes(zipcodeInput) {
  const zipRegex = /^(\d{5})(?:[-\s]?(\d{4}))?$/;
  const match = zipcodeInput.match(zipRegex);

  let zipCode = '';
  let zipPlus4 = '';
  if (match) {
    zipCode = match[1]; // "12345"
    zipPlus4 = match[2] || ''; // "6789" if present, otherwise an empty string
    return { zipCode, zipPlus4 };
    // eslint-disable-next-line no-else-return
  } else {
    console.error('Invalid ZIP code format:', zipcodeInput);
  }
}
