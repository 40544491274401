/* eslint-disable import/prefer-default-export */
// @ts-nocheck
import axios from 'utilities/authAxios';

export const addressCouldNotVerifyMessage =
  "We couldn't automatically verify the address you provided. Please review the details below and update any fields if needed. Once you're satisfied the information is correct, click 'Use this address' to continue.";

export async function validateAddressAPI(request) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/Account/ValidateAddress`,
      request
    );
    return res.data;
  } catch (err) {
    throw new Error('Address validation failed. Please try again later');
  }
}

export function formatEnteredAddress(state) {
  return `${state.addressLine1 + (state.addressLine2 ? ` ${state.addressLine2}` : '')}, ${
    state.city
  }, ${state.stateProvinceCode || state.state} ${state.postalCode}${
    state.zipPlus4 ? `-${state.zipPlus4}` : ''
  }`;
}

export function formatSuggestedAddress(response) {
  return `${response.address + (response.addressLine2 ? ` ${response.addressLine2}` : '')}, ${
    response.city
  }, ${response.state || response.stateProvinceCode} ${response.zipCode}${
    response.zipPlus4 ? `-${response.zipPlus4}` : ''
  }`;
}
