/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const VerifyAddress = (props) => {
  const { handleAddressSubmission } = props;
  const [selectedAddress, setSelectedAddress] = useState('suggested');
  const { enteredAddress, suggestedAddress } = props;

  const getHighlightedAddress = (original, suggested) => {
    const normalize = (str) => str.replace(/,/g, '').split(/\s+/);

    const originalWords = normalize(original);
    const suggestedWords = suggested.split(/(\s+|,)/);

    let originalIndex = 0;

    return suggestedWords.map((word, index) => {
      if (word === ',' || /\s+/.test(word)) {
        return word;
      }

      const cleanWord = word.replace(/,/g, '');
      const originalWord = originalWords[originalIndex] || '';

      if (originalWord !== cleanWord || originalWord.toUpperCase() !== cleanWord.toUpperCase()) {
        originalIndex++;
        return (
          <span key={index} style={{ color: 'red', fontWeight: 'bold' }}>
            {word}
          </span>
        );
      }

      originalIndex++;
      return <span key={index}>{word}</span>;
    });
  };

  const handleChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleAddressSubmission(selectedAddress);
    props.handleClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <RadioGroup value={selectedAddress} onChange={handleChange} sx={{ gap: 2 }}>
              <Paper
                elevation={0}
                sx={{
                  border: '1px solid',
                  borderColor: selectedAddress === 'original' ? '#3b82f6' : '#d1d5db',
                  borderRadius: '0.375rem',
                  p: 3,
                }}
              >
                <FormControlLabel
                  value="original"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#3b82f6',
                        },
                      }}
                    />
                  }
                  label={
                    <Box sx={{ ml: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 500, mb: 0.5 }}>
                        Your Address
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: '1.125rem' }}>
                        {enteredAddress}
                      </Typography>
                    </Box>
                  }
                  sx={{ alignItems: 'flex-start', m: 0 }}
                />
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  border: '1px solid',
                  borderColor: selectedAddress === 'suggested' ? '#3b82f6' : '#d1d5db',
                  borderRadius: '0.375rem',
                  p: 3,
                }}
              >
                <FormControlLabel
                  value="suggested"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#3b82f6',
                        },
                      }}
                    />
                  }
                  label={
                    <Box sx={{ ml: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 500, mb: 0.5 }}>
                        Suggested Address
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: '1.125rem' }}>
                        {getHighlightedAddress(enteredAddress, suggestedAddress)}
                      </Typography>
                    </Box>
                  }
                  sx={{ alignItems: 'flex-start', m: 0 }}
                />
              </Paper>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="UpdateAddress-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id="UpdateAddress-btn-updateAddress"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
};

VerifyAddress.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleAddressSubmission: PropTypes.func.isRequired,
  enteredAddress: PropTypes.string.isRequired,
  suggestedAddress: PropTypes.string.isRequired,
};

export default VerifyAddress;
