/* eslint-disable prefer-template */
import { useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import { extractZipCodes } from 'utilities/format';
import {
  formatEnteredAddress,
  formatSuggestedAddress,
  validateAddressAPI,
  addressCouldNotVerifyMessage,
} from 'components/Account/AccountMgmt/AcctMgmtHelper';
import TextField from '@mui/material/TextField';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Grid from '@mui/material/Grid';
import FormDialog from 'components/Custom/FormDialog';
import VerifyAddress from './VerifyAddress';

export default function AddAddressDialogForm(props) {
  const [state, setState] = useState({
    contactId: null,
    addressTypeId: null,
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateProvinceCode: '',
    postalCode: '',
    countryCode: '',
    zipPlus4: '',
  });
  const { contacts } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [enteredAddress, setEnteredAddress] = useState('');
  const [suggestedAddress, setSuggestedAddress] = useState('');
  const [addressNotValidated, setAddressNotValidated] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [suggestedAddressObject, setSuggestedAddressObject] = useState({});

  const submitAddAddress = () => {
    const newAddress = {
      ...state,
      contactId: state.contactId,
      addressTypeId: parseInt(state.addressTypeId, 10),
    };
    props.addAddress(newAddress);
    props.handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateAddress = async (request) => {
    try {
      const response = await validateAddressAPI(request);
      if (response === '' || response === null) {
        setAddressNotValidated(true);
        setCanProceed(true);
        props.presentAlert('error', addressCouldNotVerifyMessage);
      } else {
        setSuggestedAddressObject(response);

        const formattedEnteredAddress = formatEnteredAddress(state);
        const formattedSuggestedAddress = formatSuggestedAddress(response);

        if (formattedEnteredAddress.trim() === formattedSuggestedAddress.trim()) {
          submitAddAddress();
        } else {
          setCanProceed(false);
          setOpenDialog(true);
        }

        setEnteredAddress(formattedEnteredAddress);
        setSuggestedAddress(formattedSuggestedAddress);
      }
    } catch (error) {
      props.presentAlert('error', addressCouldNotVerifyMessage);
      setAddressNotValidated(true);
      setCanProceed(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { zipCode, zipPlus4 } = extractZipCodes(state.postalCode.trim()) || {};
    const validateAddressRequest = {
      Address: state.addressLine1?.trim() || '',
      SecondaryAddress: state.addressLine2?.trim() || '',
      City: state.city.trim(),
      State: state.stateProvinceCode?.trim() || '',
      ZipCode: zipCode,
      ZipPlus4: zipPlus4,
    };

    if (addressNotValidated === false) await validateAddress(validateAddressRequest);

    if (canProceed) submitAddAddress();
  };

  const handleAddressSubmission = (childSelectedAddressType) => {
    let newAddress = {};
    if (childSelectedAddressType === 'original') {
      newAddress = {
        ...state,
        addressTypeId: parseInt(state.addressTypeId, 10),
      };
    } else {
      newAddress = {
        ...state,
        addressLine1: suggestedAddressObject.address?.trim() || '',
        addressLine2: suggestedAddressObject.secondaryAddress?.trim() || '',
        postalCode:
          suggestedAddressObject.zipCode +
          (suggestedAddressObject.zipPlus4 ? '-' + suggestedAddressObject.zipPlus4 : ''),
        city: suggestedAddressObject.city?.trim() || '',
        stateProvinceCode: suggestedAddressObject.state?.trim() || '',
        addressTypeId: parseInt(state.addressTypeId, 10),
      };
    }
    props.addAddress(newAddress);
    props.handleClose();
  };

  const addressTypesMenuItems = Object.entries(props?.addressTypes).map((entry) => {
    return (
      <MenuItem
        key={entry[0]}
        value={entry[0]}
        id={`AddAddress-select-addressTypeValue-${entry[1]}`}
      >
        {entry[1]}
      </MenuItem>
    );
  });

  const contactIdsMenuItems = contacts.map((contact) => {
    const { firstName, lastName } = contact;
    const contactIdFullName = `${firstName} ${lastName}`;
    return (
      <MenuItem
        key={contactIdFullName.replace(' ', '')}
        value={contact.contactId}
        id={`AddAddress-select-contactIdFullNameValue-${contactIdFullName.replace(' ', '')}`}
      >
        {contactIdFullName}
      </MenuItem>
    );
  });

  const statesMenuItems = props?.provinces.map((province) => {
    return (
      <MenuItem
        key={province.stateProvinceCode}
        value={province.stateProvinceCode}
        id={`AddAddress-select-stateValue-${province.stateName}`}
      >
        {province.stateName}
      </MenuItem>
    );
  });

  const countries = [
    { countryCode: 'US', countryName: 'United States' },
    { countryCode: 'CA', countryName: 'Canada' },
  ];

  const countriesMenuItems = countries.map((country) => {
    return (
      <MenuItem
        key={country.countryCode}
        value={country.countryCode}
        id={`AddAddress-select-countryCodeValue-${country.countryCode}`}
      >
        {country.countryName}
      </MenuItem>
    );
  });
  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Contact Id"
                id="AddAddress-select-contactIdFullName"
                value={state.contactId}
                name="contactId"
                onChange={handleChange}
              >
                {contactIdsMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Address Type"
                id="AddAddress-select-addressType"
                value={state.addressTypeId}
                name="addressTypeId"
                onChange={handleChange}
              >
                {addressTypesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                label="Address Line 1"
                id="AddAddress-textField-addressLine1"
                name="addressLine1"
                value={state.addressLine1}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Address Line 2"
                id="AddAddress-textField-addressLine2"
                name="addressLine2"
                value={state.addressLine2}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="City"
                id="AddAddress-textField-city"
                name="city"
                value={state.city}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCityIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="State"
                id="AddAddress-select-state"
                value={state.stateProvinceCode}
                name="stateProvinceCode"
                onChange={handleChange}
              >
                {statesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Zip/Postal Code"
                id="AddAddress-textField-postalCode"
                name="postalCode"
                value={state.postalCode}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Country"
                id="AddAddress-select-countryCode"
                value={state.countryCode}
                name="countryCode"
                onChange={handleChange}
              >
                {countriesMenuItems}
              </TextField>
            </Grid>
          </Grid>
          <FormDialog
            formId="VerifyAddress"
            dialogTitle="Verify your address"
            externalOpen={openDialog}
            onClose={() => setOpenDialog(false)}
          >
            <VerifyAddress
              enteredAddress={enteredAddress}
              suggestedAddress={suggestedAddress}
              handleAddressSubmission={handleAddressSubmission}
            />
          </FormDialog>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="AddAddress-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button variant="contained" size="small" id="AddAddress-btn-createAddress" type="submit">
            {addressNotValidated ? 'Use this address' : 'Submit'}
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

AddAddressDialogForm.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ).isRequired,
  addAddress: PropTypes.func.isRequired,
  addressTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  provinces: PropTypes.arrayOf(
    PropTypes.shape({
      stateProvinceCode: PropTypes.string.isRequired,
      stateName: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
};
